import React from "react"
import { Grid, Row, Col } from 'react-flexbox-grid';
import ReactMarkdown from 'react-markdown';

import Layout from "../components/layout"
import SEO from "../components/seo"
import Catalog from "../services/catalog"
import ManPages from "../services/man-pages"
import BookmarkToggle from "../components/bookmarks/bookmark-toggle";

class ViewControlPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      control: null,
      mfg: null,
      page: null,
    }

    this.init = this.init.bind(this);
   }

  componentWillMount() {
    this.init();
  }

  async init() {
    const hash = this.props.location.hash;
    const id = hash ? hash.substr(1) : null;

    Catalog.getControlPanelById(id)
      .then(control => {
        this.setState({control});

        const { mfgId } = control;
        if (mfgId) {
          Catalog.getMfgById(mfgId)
            .then(mfg => this.setState({mfg}))
            .catch(console.error);
        }
      })
      .catch(console.error);

    ManPages.getControlPanelPage(id)
      .then(page => this.setState({page}))
      .catch(console.error);
  }

  render() {
    const { control, page, mfg } = this.state;
    if (!control) return null;

    const { body, profileImg } = page || {};

    return(
      <Layout navTab={3}>
        <SEO title={control.name || "Unknown Control Panel"} />
        <h1>{control.name  || "Unknown Control Panel"}</h1>
        <Grid fluid>
          <Row>
            <Col xs={12} md={6}>
            <div style={{
                backgroundColor: "Gray",
                backgroundImage: profileImg ? `url(${profileImg})` : undefined,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '256px',
                }}>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <p>Serial Number: {control.sn}</p>
              <p>Type: {control.type}</p>
              <p>Mfg: {mfg && mfg.name || 'Unknown'}</p>
              <BookmarkToggle bookmarkType='controls' itemId={control.id}/>
            </Col>
          </Row>
        </Grid>
        { (body) ? <ReactMarkdown source={body} /> : null}
      </Layout>
    );
  }
}

export default ViewControlPage